<template>
<div>
  <b-tabs pills card v-if="room">
    <b-tab title="Room" active>
      Room: {{ room | jsonstringify }}<br/>
      <hr/>
      <button class="btn btn-primary" @click="saveLogsIfNotEmpty">Download Room and Users Logs</button>
      <hr/>
      <div class="log" v-for="(log, index) in logs" v-bind:key="index">{{ log }}</div>
    </b-tab>
    <b-tab title="UserLogs Merged">
      <div :class="['log', selected[index] ? 'selected' : '']" v-for="(log, index) in userLogs" v-bind:key="index" @click="selectLog(index)">
        [{{ log.time | timetoISO }}][{{log.id}}] {{ log.message }} {{ log.data }}
      </div>
    </b-tab>
  </b-tabs>
</div>
</template>

<script>
import { db, database } from '../services/db';
import { getLog } from "../services/log";
import { saveAs } from 'file-saver';
let log = getLog('rl');

export default {
  name: "roomlogs",
  props: {
    roomId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      room: null,
      logs: [],
      userLogs: [],
      selected: [],
    }
  },
  mounted() {
    if (this.roomId)
      this.loadRoom(this.roomId);
  },
  beforeDestroy() {
    if (this.rootDb) {
      this.rootDb.off("child_added");
      this.rootDb.off("child_removed");
    }
  },
  watch: {
    roomId() {
      this.loadRoom(this.roomId);
    },
  },
  methods: {
    async loadRoom(roomId) {
      log.log("loadRoom");
      return this.$bind("room", db.collection("LiveRooms").doc(roomId)).then((room) => {
        if (!room)
          log.error("Room does not exist");
        log.log("Room loaded", room);
        this.startWatching();
        this.startWatchingUserLogs();
      });
    },
    startWatching() {
      this.rootDb = database.ref(`LiveCalls/${this.roomId}`);
      
      this.rootDb.on('child_added', (data) => {
        let msg = JSON.parse(data.val().message);
        let receiver = data.val().receiver;
        let receiverIndex = this.room.users.findIndex((u) => u == receiver);
        let sender = data.val().sender;
        let senderIndex = this.room.users.findIndex((u) => u == sender);
        let type = msg.type ? msg.type : 
          msg.renegotiate ? "renegociate" : 
          msg.transceiverRequest ? msg.transceiverRequest.kind : "";
        this.logs.push(`[${senderIndex} -> ${receiverIndex}] ${data.key} ${type}\n`);
      });

      this.rootDb.on('child_removed', (data) => {
        this.logs.push(`removing ${data.key}\n`);
      });
    },
    startWatchingUserLogs() {
      let userDb = database.ref(`Logs/${this.roomId}`);
      userDb.on('child_added', (data) => {
        this.userLogs.push(data.val());
      });
    },
    saveLogsIfNotEmpty() {
      log.log("Room logs");
      if (this.logs.length > 0) {
        let blob = new Blob(this.logs, {type: "text/plain;charset=utf-8"});
        saveAs(blob, `${this.room.id}.log`);
      }
      log.log("Room users logs");
      if (this.userLogs.length > 0) {
        let blob = new Blob(this.userLogs.map((l) => `[${new Date(l.time).toISOString()}][${l.id}] ${l.message} ${l.data}\n`), {type: "text/plain;charset=utf-8"});
        saveAs(blob, `${this.room.id}.log`);
      }
    },
    selectLog(index) {
      log.log("select", index);
      this.selected[index] = !this.selected[index];
      this.$forceUpdate();
    },
    deleteUserLogs() {
      this.userLogs = [];
    }
  }
}
</script>

<style scoped>

.log {
  font-family: monospace;
  white-space: nowrap;
}

.selected {
  white-space: normal;
  font-weight: bold;
  background-color: bisque;
}

</style>
